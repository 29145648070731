import { apiClient } from "./api";

interface LoginResponse {
    accessToken: string;
    tokenType: string;
}

export const login = async (username: string, password: string): Promise<LoginResponse> => {
    try {
        const { camelizedResponse } = await apiClient("/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ username, password }),
        });

        localStorage.setItem("access_token", camelizedResponse.accessToken);

        return camelizedResponse;
    } catch (error) {
        console.error(`Login failed for username: ${username}`, error);

        if (error instanceof Error) {
            throw new Error(`Failed to login: ${error.message}`);
        } else {
            throw new Error("An unknown error occurred during login.");
        }
    }
};

export const logout = async (): Promise<void> => {
    try {
        const token = localStorage.getItem("access_token");
        if (token) {
            localStorage.removeItem("access_token");
        } else {
            throw new Error("No access token found.");
        }

        const { response } = await apiClient("/logout", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error("Logout failed.");
        }
    } catch (error) {
        console.error("Logout failed", error);

        if (error instanceof Error) {
            throw new Error(`Failed to logout: ${error.message}`);
        } else {
            throw new Error("An unknown error occurred during logout.");
        }
    }
};


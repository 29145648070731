'use client';

import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Zoom } from '@mui/material';
import { AtfGlobalDialogButton, useAtfGlobalDialog } from '@/app/context/Apptifact/AtfGlobalDialogContext';
import JoyButton from '@/common-components/JoyButton';
import { WarningIconRed } from '@/app/assets/icons';
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';

const Icon: any = {
    error: <WarningIconRed />,
    confirm: <WarningIconRed />,
};
export default function AtfGlobalDialog() {
    const { dialogState, hideDialog } = useAtfGlobalDialog();
    const { isOpen, type, title = type === 'error' ? '오류' : '알림', message, buttons, icon, note } = dialogState;

    if (!isOpen) return null;

    const renderButtons = (buttons?: AtfGlobalDialogButton[]) => {
        if (!buttons || buttons.length === 0) {
            return (
                <JoyButton
                    disableRipple={false}
                    color="error"
                    variant="contained"
                    sx={{ width: '100%', color: '#FFF', border: (theme) => `1px solid ${theme.palette.divider}` }}
                    onClick={() => {
                        hideDialog();
                    }}
                >
                    확인
                </JoyButton>
            );
            // return <Button onClick={hideDialog}>확인</Button>;
        }

        return buttons.map((button, index) => (
            <JoyButton
                disableRipple={false}
                sx={{ width: 'auto', ...button.sx }}
                key={index}
                variant={button.variant ? button.variant : 'outlined'}
                color={button.color ? button.color : 'primary'}
                onClick={() => {
                    button.onClick();
                    hideDialog();
                }}
            >
                {button.text}
            </JoyButton>
        ));
    };

    const renderContent = () => {
        switch (type) {
            case 'loading':
                return (
                    <Dialog
                        open={isOpen}
                        sx={{
                            '& .MuiPaper-root':
                                type === 'loading'
                                    ? {
                                          borderRadius: '20px',
                                          width: '80px',
                                          height: '80px',
                                          boxShadow: '0px 16px 20px 0px rgba(0, 0, 0, 0.08)',
                                      }
                                    : {},
                            '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.24)' },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '80px',
                                height: '80px',
                                bgcolor: '#FFF;',
                                borderRadius: '20px',
                                boxShadow: '0px 16px 20px 0px rgba(0, 0, 0, 0.08)',
                            }}
                        >
                            <CircularProgress
                                thickness={4.8}
                                size={35}
                            />
                        </Box>
                    </Dialog>
                );
            case 'alert':
            case 'error':
                return renderPopup(isOpen);
        }
    };

    const renderPopup = (isOpen: boolean) => {
        return (
            <Dialog
                aria-modal
                aria-hidden={false}
                TransitionComponent={TransitionZoom}
                open={isOpen}
                disableScrollLock={true}
                sx={{ '& .MuiPaper-root': { bgcolor: '', maxWidth: '360px', width: '100%', zIndex: 999 } }}
                PaperProps={{
                    sx: {
                        minWidth: '360px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: '20px',
                        padding: '20px 20px',
                        gap: '30px',
                    },
                }}
                // {...other}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '100%', mx: 'auto', lineHeight: '100%', gap: '20px' }}>
                    <>{(Icon[type as any] || icon) && <Box sx={{ textAlign: 'center' }}>{Icon[type] ?? icon}</Box>}</>
                    <Box sx={{ textAlign: 'center' }}>
                        {title && (
                            <Typography
                                variant="16-24-700"
                                textAlign="center"
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                        )}
                        {message && (
                            <Box sx={{ pt: '20px', textAlign: 'center', width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
                                <Typography
                                    variant="14-20-500"
                                    textAlign="center"
                                    dangerouslySetInnerHTML={{ __html: message }}
                                ></Typography>
                            </Box>
                        )}
                        {note && (
                            <Box sx={{ pt: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                                <Typography
                                    variant="14-18-500"
                                    textAlign="center"
                                    color={'#797776'}
                                    dangerouslySetInnerHTML={{ __html: note }}
                                ></Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
                <DialogActions sx={{ width: '100%' }}>{renderButtons(buttons)}</DialogActions>
            </Dialog>
        );
    };

    if (type == 'confirm') return renderPopup(isOpen);

    return renderContent();
}

const TransitionZoom = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return (
        <Zoom
            ref={ref}
            {...props}
        />
    );
});

import { Button, ButtonProps, Typography } from "@mui/material";
interface Props extends ButtonProps {
    label?: string;
}
export default function JoyButton(props: Props) {
    const { sx = {}, children, label, ...other } = props;

    return (
        <Button
            disableElevation
            disableRipple
            variant="outlined"
            {...other}
            sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // color: "#FFFFFF",
                width: "120px",
                height: "44px",
                borderRadius: '4px',
                p: '12px',
                textWrap: 'nowrap',
                border: '1px solid inherit',
                '& .MuiButton-endIcon': {
                    margin: '0px'
                },
                '& .MuiButton-startIcon': {
                    margin: '0px'
                },
                ...sx,
            }}
        >
            {label && <Typography variant="14-18-500" sx={{ p: '0 8px' }} >
                {label}
            </Typography>}
            {/* <Box sx = {}>
                {endIcon}
            </Box> */}
            {children}
        </Button>
    );
}

import { Noto_Sans_KR } from 'next/font/google';
export const NotoSansKR = Noto_Sans_KR({
    weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
    subsets: ['latin'],
    variable: '--noto-sans-kr',
    // display: "swap",
});

// export font to use for typography
export const fontStyle = {
    notoSansKr: {
        variable: 'var(--noto-sans-kr)',
    },
};

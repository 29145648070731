'use client';

import { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { OverridableStringUnion } from '@mui/types';
import { ButtonPropsColorOverrides, ButtonPropsVariantOverrides, SxProps } from '@mui/material';

export type AtfGlobalDialogType = 'loading' | 'alert' | 'error' | 'confirm';

export interface AtfGlobalDialogButton {
    text: string;
    onClick: () => void;
    sx?: SxProps;
    variant?: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>;
    color?: OverridableStringUnion<'inherit' | 'secondary' | 'primary' | 'error' | 'success' | 'info' | 'warning', ButtonPropsColorOverrides> | undefined;
}

export interface AtfGlobalDialogOptions {
    note?: string;
    icon?: ReactNode;
    title?: string;
    buttons?: AtfGlobalDialogButton[];
}

export interface AtfGlobalDialogState extends AtfGlobalDialogOptions {
    isOpen: boolean;
    type: AtfGlobalDialogType;
    message: string;
    icon?: ReactNode;
    note?: string;
}

interface AtfGlobalDialogContextType {
    dialogState: AtfGlobalDialogState;
    showLoading: () => void;
    showAlertDialog: (message: string, options?: AtfGlobalDialogOptions) => void;
    showErrorDialog: (message: string, options?: AtfGlobalDialogOptions) => void;
    showConfirmDialog: (message: string, options?: AtfGlobalDialogOptions) => void;
    showDialog: (type: AtfGlobalDialogType, message: string, options: AtfGlobalDialogOptions) => void;
    hideDialog: () => void;
}

const AtfGlobalDialogContext = createContext<AtfGlobalDialogContextType | undefined>(undefined);

const initialState: AtfGlobalDialogState = {
    isOpen: false,
    type: 'alert',
    message: '',
};

export const AtfGlobalDialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [dialogState, setDialogState] = useState<AtfGlobalDialogState>(initialState);

    const showDialog = useCallback((type: AtfGlobalDialogType, message: string, options: AtfGlobalDialogOptions) => {
        setDialogState({ ...options, message, type, isOpen: true });
    }, []);

    const hideDialog = useCallback(() => {
        setDialogState((prev) => ({ ...prev, isOpen: false }));
    }, []);

    const showLoading = useCallback(() => {
        showDialog('loading', '', {
            title: '',
        });
    }, [showDialog]);

    const showAlertDialog = useCallback(
        (message: string, options?: AtfGlobalDialogOptions) => {
            showDialog('alert', message, options || {});
        },
        [showDialog]
    );

    const showErrorDialog = useCallback(
        (message: string, options?: AtfGlobalDialogOptions) => {
            showDialog('error', message, options || {});
        },
        [showDialog]
    );

    const showConfirmDialog = useCallback(
        (message: string, options?: AtfGlobalDialogOptions) => {
            showDialog('confirm', message, options || {});
        },
        [showDialog]
    );

    return (
        <AtfGlobalDialogContext.Provider value={{ dialogState, showLoading, showAlertDialog, showErrorDialog, showDialog, showConfirmDialog, hideDialog }}>{children}</AtfGlobalDialogContext.Provider>
    );
};

export const useAtfGlobalDialog = () => {
    const context = useContext(AtfGlobalDialogContext);
    if (!context) {
        throw new Error('useAtfGlobalDialog must be used within a AtfGlobalDialogProvider');
    }
    return context;
};

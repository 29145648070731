import { CreateAddressRequest, DataAddressResponseSuccess, DataAddressResponseSuccessCreate, DataAddressResponseSuccessUpdate, IAddressData, UpdateAddressRequest } from '@/models/orders';
import { CreateUserRequest, IResponseCommonUser, PaginatedUserResponse, UpdateUserRequest, User } from '../models';
import { apiClient, convertToFormData, toQueryParams, transformDates } from './api';

export const fetchUsers = async (page?: number, perPage?: number): Promise<PaginatedUserResponse> => {
    try {
        const url = (() => {
            const baseUrl = '/users';
            const params: Record<string, any> = {
                page: (page ?? 0) + 1,
                per_page: perPage,
            };
            const queryString = toQueryParams(params);

            return queryString ? `${baseUrl}?${queryString}` : baseUrl;
        })();

        const { camelizedResponse } = await apiClient(url, {
            method: 'GET',
        });

        return camelizedResponse as PaginatedUserResponse;
    } catch (error) {
        console.error('Failed to fetch users', error);

        if (error instanceof Error) {
            throw new Error(`Failed to fetch users: ${error.message}`);
        } else {
            throw new Error('An unknown error occurred while fetching users.');
        }
    }
};

export const fetchStaffUsers = async (): Promise<PaginatedUserResponse> => {
    try {
        const { camelizedResponse } = await apiClient(`/users?role=staff`, {
            method: 'GET',
        });

        return camelizedResponse as PaginatedUserResponse;
    } catch (error) {
        console.error('Failed to fetch staff users', error);

        if (error instanceof Error) {
            throw new Error(`Failed to fetch staff users: ${error.message}`);
        } else {
            throw new Error('An unknown error occurred while fetching staff users.');
        }
    }
};

export const fetchUser = async (userId: number): Promise<User> => {
    try {
        const { camelizedResponse } = await apiClient(`/users/${userId}`, {
            method: 'GET',
        });

        return transformDates<User>(camelizedResponse.data);
    } catch (error) {
        console.error(`Failed to fetch user ${userId}`, error);
        console.log('first', error);
        if (error instanceof Error) {
            throw new Error(`Failed to fetch user ${userId}: ${error.message}`);
        } else {
            throw new Error(`An unknown error occurred while fetching user ${userId}.`);
        }
    }
};

export const postUser = async (user: CreateUserRequest): Promise<any> => {
    try {
        const { camelizedResponse } = await apiClient('/users', {
            method: 'POST',
            body: convertToFormData(user),
        });

        return camelizedResponse;
    } catch (error) {
        console.error('Error creating user: ', error);
        throw error;
    }
};

export const putUser = async (user: UpdateUserRequest): Promise<any> => {
    try {
        const formData = convertToFormData(user);

        formData.append(`_method`, 'PUT');

        const { camelizedResponse } = await apiClient(`/users/${user.id}`, {
            method: 'POST',
            body: formData,
        });

        return camelizedResponse;
    } catch (error) {
        console.error('Error updating user: ', error);
        throw error;
    }
};

export const fetchCommonUser = async (params?: any): Promise<IResponseCommonUser> => {
    try {
        const url = params ? `/common/users?${toQueryParams(params)}` : '/common/users';
        const { camelizedResponse } = await apiClient(url, {
            method: 'GET',
        });
        return camelizedResponse as IResponseCommonUser;
    } catch (error) {
        console.error('Error fetching common user: ', error);
        throw error;
    }
};

export const deleteUsers = async (userId: number): Promise<any> => {
    try {
        const { camelizedResponse } = await apiClient(`/users/${userId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return camelizedResponse;
    } catch (error) {
        console.error('Error deleting announcement: ', error);
        throw error;
    }
};

export const actionSuspend = async (userId: number): Promise<any> => {
    try {
        const { camelizedResponse } = await apiClient(`/users/${userId}/suspend`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return camelizedResponse;
    } catch (error) {
        console.error('Error deleting announcement: ', error);
        throw error;
    }
};

export const fetchAddressUser = async (userId: number): Promise<DataAddressResponseSuccess> => {
    try {
        const { camelizedResponse } = await apiClient(`/users/${userId}/address`, {
            method: 'GET',
        });
        return camelizedResponse;
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(`Failed to get user's address: ${error.message}`);
        } else {
            throw new Error(`An unknown error occurred while fetching fetch user's address.`);
        }
    }
};

export const addAddress = async (userId: number, body: CreateAddressRequest): Promise<DataAddressResponseSuccessCreate> => {
    try {
        const { camelizedResponse } = await apiClient(`/users/${userId}/address`, {
            method: 'POST',
            body: convertToFormData(body),
        });
        return camelizedResponse;
    } catch (error) {
        console.error(`Failed to create user's address ${userId}`, error);
        console.log('first', error);
        if (error instanceof Error) {
            throw new Error(`Failed to create user's address ${userId}: ${error.message}`);
        } else {
            throw new Error(`An unknown error occurred while fetching fetch user's address ${userId}.`);
        }
    }
};

export const updateAddress = async (userId: number, addressId: number, body: UpdateAddressRequest): Promise<DataAddressResponseSuccessUpdate> => {
    try {
        const urlEncodedBody = new URLSearchParams(convertToFormData(body) as any).toString();

        const { camelizedResponse } = await apiClient(`/users/${userId}/address/${addressId}`, {
            method: 'PATCH',
            body: urlEncodedBody,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        return camelizedResponse;
    } catch (error) {
        console.error(`Failed to create user's address ${userId}`, error);
        console.log('first', error);
        if (error instanceof Error) {
            throw new Error(`Failed to create user's address ${userId}: ${error.message}`);
        } else {
            throw new Error(`An unknown error occurred while fetching fetch user's address ${userId}.`);
        }
    }
};

export const deleteAddress = async (userId: number, addressId: number): Promise<any> => {
    try {
        const { camelizedResponse } = await apiClient(`/users/${userId}/address/${addressId}`, {
            method: 'DELETE',
        });
        return camelizedResponse;
    } catch (error) {
        console.error(`Failed to fetch user's address ${userId}`, error);
        console.log('first', error);
        if (error instanceof Error) {
            throw new Error(`Failed to fetch user's address ${userId}: ${error.message}`);
        } else {
            throw new Error(`An unknown error occurred while fetching fetch user's address ${userId}.`);
        }
    }
};

import { Height } from "@mui/icons-material";
import { createTheme } from "@mui/material";
import { fontStyle, NotoSansKR } from "./font";
declare module '@mui/material/styles' {
    interface TypographyVariants {
        '12-16-400': React.CSSProperties;
        '12-16-500': React.CSSProperties;
        '14-18-500': React.CSSProperties;
        '14-20-400': React.CSSProperties;
        '14-20-500': React.CSSProperties;
        '14-20-700': React.CSSProperties;
        '16-24-400': React.CSSProperties;
        '16-24-500': React.CSSProperties;
        '16-24-700': React.CSSProperties;
        '20-30-500': React.CSSProperties;
    }
    interface TypographyVariantsOptions {
        '12-16-400'?: React.CSSProperties;
        '12-16-500'?: React.CSSProperties;
        '14-18-500'?: React.CSSProperties;
        '14-20-400'?: React.CSSProperties;
        '14-20-500'?: React.CSSProperties;
        '14-20-700'?: React.CSSProperties;
        '16-24-400'?: React.CSSProperties;
        '16-24-500'?: React.CSSProperties;
        '16-24-700'?: React.CSSProperties;
        '20-30-500'?: React.CSSProperties;
    }
}
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        '12-16-400': true;
        '12-16-500': true;
        '14-18-500': true;
        '14-20-400': true;
        '14-20-500': true;
        '14-20-700': true;
        '16-24-400': true;
        '16-24-500': true;
        '16-24-700': true;
        '20-30-500': true;
    }
}
export default createTheme({
    palette: {
        primary: {
            main: "#2073F6",
            contrastText: "#fff",
        },
        secondary: {
            main: "#797776",
            contrastText: "#313030",
        },
        success: {
            main: '#27BA8E',
            contrastText: "#27BA8E",
        },
        error: {
            main: '#FF2D2D',
            contrastText: "#FF2D2D",
        },
        text: {
            primary: "#313030",
            secondary: "#797776",
        },
        divider: '#E5E2E1'
    },
    typography: {
        fontFamily: [fontStyle.notoSansKr.variable, 'sans-serif'].join(","),
        fontSize: 14,
        h3: {
            fontSize: "36px",
            lineHeight: "44px",
            fontWeight: 600,
        },
        h5: {
            fontSize: "20px",
            lineHeight: "30px",
            fontWeight: 500,
        },
        '12-16-400': {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: '400',
            fontStyle: 'normal',
        },
        '12-16-500': {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: '500',
            fontStyle: 'normal',
        },
        '14-18-500': {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '500',
            fontStyle: 'normal',
        },
        '14-20-400': {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '400',
            fontStyle: 'normal',
        },
        '14-20-500': {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '500',
            fontStyle: 'normal',
        },
        '14-20-700': {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '700',
            fontStyle: 'normal',
        },
        '16-24-400': {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '400',
            fontStyle: 'normal',
        },
        '16-24-500': {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '500',
            fontStyle: 'normal',
        },
        '16-24-700': {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '700',
            fontStyle: 'normal',
        },
        '20-30-500': {
            fontSize: '20px',
            lineHeight: '30px',
            fontWeight: '500',
            fontStyle: 'normal',
        },
    },
});

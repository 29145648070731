"use client";

import { ReactNode } from "react";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import theme from "./theme";
import AtfGlobalDialog from "../components/Apptifact/AtfGlobalDialog";
import { AtfGlobalDialogProvider } from "../context/Apptifact/AtfGlobalDialogContext";
import { AtfApiErrorHandlerProvider } from "../context/Apptifact/AtfApiErrorHandlerContext";

export default function ClientRootLayout({
    children,
}: Readonly<{
    children: ReactNode;
}>) {
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <AtfGlobalDialogProvider>
                    <AtfApiErrorHandlerProvider>{children}</AtfApiErrorHandlerProvider>
                    <AtfGlobalDialog />
                </AtfGlobalDialogProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

'use client';

import React, { createContext, useContext, ReactNode, ReactElement } from 'react';
import { useAtfGlobalDialog } from './AtfGlobalDialogContext';
import { useRouter } from 'next/navigation';
import { useTheme } from '@mui/material';

interface AtfApiErrorHandlerContextType {
    handleApiError: (error: any, message?: string, icon?: ReactElement) => void;
}

const AtfApiErrorHandlerContext = createContext<AtfApiErrorHandlerContextType | undefined>(undefined);

export const AtfApiErrorHandlerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { showErrorDialog } = useAtfGlobalDialog();
    const router = useRouter();
    const theme = useTheme();
    const handleApiError = (error: any, message?: string, icon?: ReactElement) => {
        let errorMessage: string = message || '서버 에러가 발생했습니다.<br>기술지원팀에 문의해주세요.';
        let handleClickConfirm = () => {};

        if (typeof error === 'string') {
            errorMessage = error;
        } else if (error instanceof Error) {
            if (error.cause) {
                const cause = error.cause as { response: Response; rawData: any };
                const statusCode = cause.response.status;
                const rawData = cause.rawData;

                switch (statusCode) {
                    case 401:
                        errorMessage = `로그인 정보가 만료되었습니다.<br>로그인 페이지로 이동합니다.`;

                        handleClickConfirm = () => {
                            const token = localStorage.getItem('access_token');

                            if (token) {
                                localStorage.removeItem('access_token');
                            }

                            router.push('/login');
                        };
                        break;

                    default:
                        if (rawData) errorMessage += `<br>${rawData}`;
                        break;
                }
            }
        }

        if (error.message) {
            errorMessage += '<br><br>' + error.message;
        }

        showErrorDialog(errorMessage, {
            icon: icon ?? undefined,
            buttons: [
                {
                    variant: 'contained',
                    color: 'error',
                    text: '확인',
                    sx: { width: '100%', color: '#FFF' },
                    onClick: handleClickConfirm,
                },
            ],
        });
    };

    return <AtfApiErrorHandlerContext.Provider value={{ handleApiError }}>{children}</AtfApiErrorHandlerContext.Provider>;
};

export const useAtfApiErrorHandler = () => {
    const context = useContext(AtfApiErrorHandlerContext);
    if (context === undefined) {
        throw new Error('useAtfApiErrorHandler must be used within an AtfApiErrorHandlerProvider');
    }
    return context;
};

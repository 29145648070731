"use client";

import Image from "next/image";
import { useRouter } from "next/navigation";
import { FormEvent, useEffect, useState } from "react";
import { Box, Button, InputAdornment, Paper, Stack, TextField, Typography } from "@mui/material";
import logo from "@/app/assets/images/logo.png";
import userIcon from "@/app/assets/images/user.svg?url";
import LockIcon from "@/app/assets/images/lock.svg?url";
import { login } from "@/app/services";
import { useAtfGlobalDialog } from "@/app/context/Apptifact/AtfGlobalDialogContext";
import { useAtfApiErrorHandler } from "@/app/context/Apptifact/AtfApiErrorHandlerContext";

export default function Login() {
    const router = useRouter();
    const { showLoading, hideDialog } = useAtfGlobalDialog();
    const { handleApiError } = useAtfApiErrorHandler();

    const [ready, setReady] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("access_token");

        if (token) {
            router.replace("/challenges");
        } else {
            setReady(true);
        }
    }, [router]);

    const handleLogin = async () => {
        try {
            showLoading();

            await login(username, password);

            hideDialog();

            router.push("/challenges");
        } catch (error) {
            handleApiError(error, "아이디 또는 비밀번호를 다시 확인해주세요.");
        }
    };

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        handleLogin();
    };

    return (
        <>
            {ready && (
                <Paper
                    elevation={0}
                    sx={{
                        width: "100vw",
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                    }}
                >
                    <Stack px="40px" flexDirection="row" alignItems="center" height="84px">
                        <Image src={logo} width={86} height={40} alt="조이쌤"></Image>
                    </Stack>
                    <Box
                        component="main"
                        sx={{
                            bgcolor: "#f0f0f0",
                            display: "flex",
                            flexGrow: 1,
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "360px",
                            }}
                        >
                            <Typography
                                variant="h3"
                                sx={{
                                    color: "#2073F6",
                                }}
                            >
                                관리자 포털 로그인
                            </Typography>
                            <Typography
                                variant="h5"
                                sx={{
                                    mt: "8px",
                                }}
                            >
                                조이쌤 관리자만 이용할 수 있습니다.
                            </Typography>

                            <Box
                                component="form"
                                onSubmit={onSubmit}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    mt: "60px",
                                    width: "360px",
                                }}
                            >
                                <TextField
                                    name="username"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="아이디"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Image src={userIcon} width={24} height={24} alt="User"></Image>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        bgcolor: "#fff",
                                        borderRadius: "8px",
                                        borderColor: "#E5E2E1",
                                    }}
                                ></TextField>
                                <TextField
                                    type="password"
                                    name="password"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="비밀번호"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Image src={LockIcon} width={24} height={24} alt="User"></Image>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        bgcolor: "#fff",
                                        borderRadius: "8px",
                                        borderColor: "#E5E2E1",
                                        mt: "16px",
                                    }}
                                ></TextField>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    disableElevation
                                    disabled={!username.length || !password.length}
                                    sx={{
                                        mt: "32px",
                                        height: "56px",
                                        borderRadius: "8px",
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        lineHeight: "20px",
                                    }}
                                >
                                    로그인
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            )}
        </>
    );
}

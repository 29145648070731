import camelcaseKeys from "camelcase-keys";
import dayjs from "dayjs";
import decamelize from "decamelize";

export interface ApiClientResponse {
    response: Response;
    jsonResponse: any;
    camelizedResponse: any;
}

export const apiClient = async (endpoint: string, options: RequestInit = {}): Promise<ApiClientResponse> => {
    const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL as string;
    const token = localStorage.getItem("access_token");
    const defaultHeaders: HeadersInit = {
        Accept: "application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
    };
    const config: RequestInit = {
        ...options,
        headers: {
            ...defaultHeaders,
            ...options.headers,
        },
    };
    const requestUrl = `${baseUrl}${endpoint}`;

    try {
        const response = await fetch(requestUrl, config);
        const jsonResponse = await response.json();

        if (!response.ok) {
            const errorMsg = jsonResponse.error || jsonResponse.message || "Server returned an error response.";
            console.error("Server Error:", errorMsg);

            throw new Error(errorMsg, { cause: { response, jsonResponse } });
        }

        const camelizedResponse = camelcaseKeys(jsonResponse, { deep: true });

        return {
            response,
            jsonResponse,
            camelizedResponse,
        };
    } catch (error) {
        if (error instanceof SyntaxError) {
            console.error("Failed to parse JSON response:", error);
            throw new Error("Failed to parse the server response as JSON.", { cause: error });
        } else if (error instanceof Error && error.cause) {
            console.error("Server Error:", error);
            throw error;
        } else {
            console.error("Network or server error:", error);
            throw new Error("A network error or server issue occurred.", { cause: error });
        }
    }
};

export function convertToFormData(entity: Object): FormData {
    const formData = new FormData();

    Object.keys(entity).forEach((key) => {
        appendToFormData(formData, decamelize(key), (entity as any)[key]);
    });

    return formData;
}

export function appendToFormData(formData: FormData, key: string, value: any) {
    if (dayjs.isDayjs(value)) {
        formData.append(key, value.toISOString());
    } else if (value instanceof File) {
        formData.append(key, value);
    } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
            appendToFormData(formData, `${key}[${index}]`, item);
        });
    } else if (value !== null && typeof value === "object") {
        Object.keys(value).forEach((subKey) => {
            appendToFormData(formData, `${key}[${decamelize(subKey)}]`, value[subKey]);
        });
    } else {
        formData.append(key, value != null ? value.toString() : "");
    }
}

export function toQueryParams(params: Record<string, any>): string {
    return new URLSearchParams(Object.entries(params).filter(([_, value]) => value !== undefined && value !== null)).toString();
}

export function transformDates<T>(data: any): T {
    const transformedData: any = { ...data };

    Object.keys(transformedData).forEach((key) => {
        if (isDateField(key) && transformedData[key] !== null) {
            transformedData[key] = dayjs(transformedData[key]);
        }
    });

    return transformedData as T;
}

export function isDateField(key: string): boolean {
    const dateFields = ["publishAt", "startAt", "endAt", "winnersAnnouncedAt", "createdAt", "updatedAt", "lastAccessAt"];

    return dateFields.includes(key);
}
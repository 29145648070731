import { Banner, CreateBannerRequest, PaginatedBannerResponse, UpdateBannerRequest } from "../models";
import { apiClient, convertToFormData, toQueryParams, transformDates } from "./api";

export const fetchBanners = async (params?: Record<string, any>): Promise<PaginatedBannerResponse> => {
    const url = (() => {
        const baseUrl = "/banners";
        return params ? `${baseUrl}?${toQueryParams(params)}` : baseUrl;

    })();
    try {
        const { camelizedResponse } = await apiClient(url, {
            method: "GET",
        });
        if ("data" in camelizedResponse && Array.isArray(camelizedResponse.data)) {
            camelizedResponse.data = camelizedResponse.data.map((item: Banner) => transformDates(item));
        }

        return camelizedResponse as PaginatedBannerResponse;
    } catch (error) {
        console.error("Failed to fetch banners", error);

        if (error instanceof Error) {
            throw new Error(`Failed to fetch banners: ${error.message}`);
        } else {
            throw new Error("An unknown error occurred while fetching banners.");
        }
    }
};


export const fetchBanner = async (bannerId: number): Promise<Banner> => {
    try {
        const { camelizedResponse } = await apiClient(`/banners/${bannerId}`, {
            method: "GET",
        });
        return transformDates<Banner>(camelizedResponse.data);
    } catch (error) {
        console.error(`Failed to fetch banner ${bannerId}`, error);

        if (error instanceof Error) {
            throw new Error(`Failed to fetch banner ${bannerId}: ${error.message}`);
        } else {
            throw new Error(`An unknown error occurred while fetching banner ${bannerId}.`);
        }
    }
};

export const postBanner = async (banner: CreateBannerRequest): Promise<any> => {
    try {
        const { camelizedResponse } = await apiClient("/banners", {
            method: "POST",
            body: convertToFormData(banner),
        });

        return camelizedResponse;
    } catch (error) {
        console.error("Error creating banner: ", error);
        throw error;
    }
};

export const putBanner = async (banner: UpdateBannerRequest): Promise<any> => {
    try {
        const formData = convertToFormData(banner);
        formData.append(`_method`, "POST");
        const { camelizedResponse } = await apiClient(`/banners/${banner.id}`, {
            method: "POST",
            body: formData,
        });
        return camelizedResponse;
    } catch (error) {
        console.error("Error updating banner: ", error);
        throw error;
    }
};

export const deleteBanners = async (bannerIds: number[]): Promise<any> => {
    try {
        const { camelizedResponse } = await apiClient(`/banners`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ids: bannerIds,
            }),
        });

        return camelizedResponse;
    } catch (error) {
        console.error("Error deleting banner: ", error);
        throw error;
    }
};
